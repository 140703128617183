
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* GlobalStyles.css */

/* Styling the scrollbar for WebKit browsers such as Chrome, Safari */
*::-webkit-scrollbar {
  width: 8px; /* narrower width for a more refined look */
  height: 8px; /* height for horizontal scrollbars */
  background-color: transparent; /* transparent background on the scrollbar itself */
}

*::-webkit-scrollbar-track {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #f0f0f0 50%, rgba(255, 255, 255, 0) 100%); /* subtle gradient for track */
  border-radius: 10px; /* fully rounded ends for the scrollbar track */
  margin: 4px; /* spacing from the edge of the scrollbar */
}

*::-webkit-scrollbar-thumb {
  background-color: #b6b6b6; /* neutral color for thumb */
  border-radius: 10px; /* fully rounded ends for the scrollbar thumb */
  border: 2px solid transparent; /* transparent border for seamless merging with the track */
  background-clip: padding-box; /* ensures the border doesn't overlap the background */
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #9e9e9e; /* slightly darker shade on hover for better visibility */
}

*::-webkit-scrollbar-thumb:active {
  background-color: #757575; /* darker color when the thumb is clicked */
}

*::-webkit-scrollbar-corner {
  background-color: transparent; /* makes the corner where horizontal and vertical scrollbars meet transparent */
}

/* Optional: Styling the scrollbar for Firefox 64 and above */
* {
  scrollbar-width: thin; /* "auto" or "thin" for less intrusive design */
  scrollbar-color: #b6b6b6 #f0f0f0; /* thumb and track color, matching the WebKit styles */
}

/* Adding transitions for smoother interactions */
*::-webkit-scrollbar-thumb, *::-webkit-scrollbar-track {
  transition: background-color 0.2s ease; /* smooth color transition for interactive elements */
}
